import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { navigate } from 'gatsby';
import Layout from 'components/layout';
import Button from '../components/button';

const NotFound = () => (
  <Layout>
    <Box align="center" justify="center" height="80vh">
      <Heading size="medium">Page Not Found</Heading>
      <Box fill="horizontal" pad="large">
        <Text textAlign="center">Sorry! Please check out one of my other pages!</Text>
      </Box>
      <Box align="center" direction="row" gap="medium" pad="large">
        <Button 
          label="Home"
          onClick={ () => navigate('/') }
        />
        <Button 
          label="Bio"
          onClick={ () => navigate('/bio') }
        />
        <Button 
          label="Method"
          onClick={ () => navigate('/method') }
        />
      </Box>
    </Box>
  </Layout>
);

export default NotFound;
